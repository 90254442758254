<template>
  <div class="orders-index width-100">
    <el-row
      v-loading="loading"
      class="view-content"
      type="flex"
      justify="start"
      align="left"
      :gutter="60"
    >
      <el-col
        id="editOrderSamplesColumn"
        :span="10"
        class="samples-wrapper"
      >
        <div
          id="samplesColumnHeader"
          class="samples-header"
        >
          <el-row
            type="flex"
            align="middle"
            class="sample__action-row"
          >
            <el-col :span="12">
              <ShowItem :label="$t('orders.orderSamples')">
                <el-button
                  slot="value"
                  size="small"
                  type="primary"
                  @click="() => {
                    initialSampleData = {};
                    isModalEdit = false;
                    openCreateSampleModal = true;
                  }"
                >
                  {{ $t('global.add') }}
                </el-button>
              </ShowItem>
              <CreateSample
                v-if="openCreateSampleModal"
                :order="order"
                :is-modal-edit="isModalEdit"
                :initial-data="initialSampleData"
                :samples="orderSamples"
                :multiple-selection="selectedSamplesList"
                @selectionChanged="changeSampleSelection"
                @cloneSample="cloneSample"
                @editSample="editSample"
                @selectOnlyThisSampleOn="selectOnlyThisSampleOn"
                @selectOnlyThisSampleOff="selectOnlyThisSampleOff"
                @deleteSample="deleteSample"
                @dialogClose="handleSampleDialogClose"
              />
            </el-col>
            <el-col :span="12">
              <ShowItem
                :label="$t('samples.create.import')"
                :help="$t('tooltips.massiveXlsOrder')"
              >
                <el-upload
                  slot="value"
                  ref="upload"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  action=""
                  style="float: left; margin-right: 0.5rem;"
                  :limit="1"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :on-exceed="handleExceed"
                  :auto-upload="false"
                  :multiple="false"
                  :data="file"
                >
                  <el-button
                    size="small"
                    type="primary"
                  >
                    {{ $t('global.add') }}
                  </el-button>
                </el-upload>
                <el-button
                  slot="value"
                  target="blank"
                  class="el-button el-button--primary el-button--small"
                  style="text-decoration: none;"
                  @click.prevent="donwloadSamplesTemplate()"
                >
                  {{ $t('samples.create.downloadExample') }}
                </el-button>
              </ShowItem>
              <Modal
                v-if="openHelpModal"
                :title="$t('global.help')"
                @dialog-close="openHelpModal = false"
              >
                <!-- eslint-disable -->
                <div
                  slot="content"
                  class="order-upload-help-content"
                  v-html="orderUploadHelp"
                />
                <!-- eslint-enable -->
              </Modal>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            align="middle"
          >
            <el-col :span="12">
              <el-form>
                <el-form-item class="form-item">
                  <span slot="label">
                    {{ $t('orders.create.customerOrderNumber') }}
                    <InfoTooltip
                      :tooltip-content="$t('tooltips.customerOrderNumber')"
                    />
                  </span>
                  <el-input
                    v-model="order.customerOrderNumber"
                    :disabled="canUse(['user', 'ordersManage'], true) || order.status !== 'draft'"
                  />
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
        <div id="samplesTableWrapper">
          <SampleItems
            :samples="orderSamples"
            :multiple-selection="selectedSamplesList"
            :all-samples-checked="allSamplesChecked"
            :is-order-read-only="isOrderReadOnly"
            @selectionChanged="changeSampleSelection"
            @selectOnlyThisSampleOn="selectOnlyThisSampleOn"
            @selectOnlyThisSampleOff="selectOnlyThisSampleOff"
            @cloneSample="cloneSample"
            @editSample="editSample"
            @deleteSample="deleteSample"
          />
        </div>
      </el-col>
      <el-col
        v-if="Object.keys(selectedSamples).length"
        id="editOrderAnalysesColumn"
        :span="14"
        class="analyses-wrapper"
      >
        <div id="analysesColumnHeader">
          <Filters
            v-show="!selectOnlyThisSampleEnabled"
            :filters="sampleAnalysesFilters"
            filter-key="sampleAnalyses"
            :initial-data="filtersStatus.sampleAnalyses"
          />
          <AccreditedTestModal
            v-if="accreditedTestModalVisible"
            :uncompleted-samples="uncompletedSamples"
            @dialogClose="handleAccreditedTestModalClose"
          />
          <dynamicAnalysesModal
            v-if="dynamicAnalysesModalVisible"
            :analysis="currentDynamicClass"
            @dialogClose="dynamicAnalysesModalClose"
          />
        </div>
        <div id="analysesTableWrapper">
          <TableWrapper
            v-loading="analysisloading"
            :table-columns="sampleAnalysesColumns"
            :row-class-name="(row) => row.hasExpiredOffer ? 'expired-row' : ''"
            table-key="sampleAnalyses"
            height="100%"
          >
            <template v-slot:beforeColumns>
              <el-table-column
                class="custom-select"
                align="center"
                min-width="35px"
                fixed="left"
              >
                <template
                  slot-scope="scope"
                >
                  <el-checkbox
                    v-model="scope.row.selected"
                    :indeterminate="isIndeterminate(scope.row)"
                    @change="(value) => handleAnalysisSelection(scope.row, value)"
                  />
                </template>
              </el-table-column>
            </template>
          </TableWrapper>
        </div>

        <div id="analysesColumnFooter">
          <ContentButtons>
            <el-button
              type="secondary"
              native-type="submit"
              @click="backWithoutSaving('ordersIndex')"
            >
              {{ $t('orders.backWithoutSaving') }}
            </el-button>
            <el-button
              type="primary"
              native-type="submit"
              @click="goToSummary()"
            >
              {{ $t('orders.goToSummary') }}
            </el-button>
          </ContentButtons>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<!-- eslint-disable no-param-reassign -->
<script>
import storage from 'store';
import { mapGetters, mapActions } from 'vuex';
import {
  ordersApi,
  offersApi,
  samplesApi,
  downloadsApi,
} from '@/api';
import fileSaver from 'file-saver';
import { sampleAnalysesColumns, sampleAnalysesFilters } from '@/config';
import Filters from '@/components/Filters.vue';
import TableWrapper from '@/components/TableWrapper.vue';
import CreateSample from '@/views/orders/CreateSample.vue';
import SampleItems from '@/components/SampleItems.vue';
import ContentButtons from '@/components/ContentButtons.vue';
import ShowItem from '@/components/ShowItem.vue';
import Modal from '@/components/Modal.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import acl from '@/services/acl';
// import orderUploadHelp from '@/config/help';
import AccreditedTestModal from './AccreditedTestModal.vue';
import DynamicAnalysesModal from './DynamicAnalysesModal.vue';


export default {
  name: 'EditOrder',
  components: {
    Filters,
    TableWrapper,
    CreateSample,
    SampleItems,
    ContentButtons,
    ShowItem,
    Modal,
    AccreditedTestModal,
    DynamicAnalysesModal,
    InfoTooltip,
  },
  data() {
    return {
      accreditedTestModalVisible: false,
      uncompletedSamples: [],
      currentAccreditedAnalysis: null,
      dynamicAnalysesModalVisible: false,
      currentDynamicClass: null,
      file: null,
      sampleAnalysesFilters,
      sampleAnalysesColumns,
      openCreateSampleModal: false,
      loading: false,
      order: {},
      orderSamples: [],
      isModalEdit: false,
      isOrderReadOnly: false,
      initialSampleData: {},
      selectedSamples: {},
      selectedSampleAnalyses: {},
      selectedSamplesList: [],
      postAnalysesData: [],
      openHelpModal: false,
      publicPath: process.env.BASE_URL,
      analysisloading: false,
      selectOnlyThisSampleEnabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
      'tableParams',
      'currentRoute',
      'userPermissions',
    ]),
    allSamplesChecked() {
      if (this.orderSamples.length === 0) return false;
      return this.orderSamples.every(s => this.selectedSamplesList.includes(s));
    },
  },
  async created() {
    this.parseQueryStringStatus(this.$route.query);
    await this.getOrder();
    await this.getOrderSamples();
    this.tableRows();
    this.precompileDraft();
    this.loadSampleSelection();
    this.loadAnalysisSelection();
  },
  mounted() {
    this.sampleTableHeight();
    this.analysesTableHeight();
    this.applyDefaultFilters();
  },
  updated() {
    this.analysesTableHeight();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== null && !['editOrder', 'editOrderSample', 'cloneOrderSample', 'orderSummary'].includes(from.name)) {
      storage.remove('selectedSamples');
      storage.remove('selectedSampleAnalyses');
    }
    next((vm) => {
      switch (to.name) {
        case 'cloneOrderSample':
          vm.getOrderSample(false);
          break;
        case 'editOrderSample':
          vm.getOrderSample();
          break;
        default:
          break;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.getOrder();
    this.getOrderSamples();
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    sampleTableHeight() {
      const editOrderSamplesColumn = document.getElementById('editOrderSamplesColumn');
      const samplesColumnHeader = document.getElementById('samplesColumnHeader');
      const samplesTableWrapper = document.getElementById('samplesTableWrapper');
      if (editOrderSamplesColumn && samplesColumnHeader && samplesTableWrapper) {
        samplesTableWrapper.style.height = `${editOrderSamplesColumn.offsetHeight
          - samplesColumnHeader.offsetHeight}px`;
      }
    },
    analysesTableHeight() {
      const editOrderAnalysesColumn = document.getElementById('editOrderAnalysesColumn');
      const analysesColumnHeader = document.getElementById('analysesColumnHeader');
      const analysesColumnFooter = document.getElementById('analysesColumnFooter');
      const analysesTableWrapper = document.getElementById('analysesTableWrapper');
      if (editOrderAnalysesColumn
          && analysesColumnHeader && analysesColumnFooter
          && analysesTableWrapper) {
        const analysesTableContainer = analysesTableWrapper.getElementsByClassName('table-content');
        const analysesTablePaginationContainer = analysesTableWrapper.getElementsByClassName('pagination-content');

        if (analysesTableContainer && analysesTablePaginationContainer) {
          analysesTableContainer[0].style.height = `${editOrderAnalysesColumn.offsetHeight
            - analysesColumnHeader.offsetHeight - analysesColumnFooter.offsetHeight - analysesTablePaginationContainer[0].offsetHeight}px`;
        }
      }
    },
    changeSampleSelection({ row: sampleRow, value }) {
      if (value) this.addSampleToSelectedSamples(sampleRow.id, sampleRow);
      else this.removeSampleFromSelectedSamples(sampleRow.id);
      this.loadAnalysisSelection();
    },
    loadAnalysisSelection() {
      const { rows } = this.tableParams('sampleAnalyses');
      const updatedRows = rows.map(row => ({
        ...row,
        selected: Object.keys(this.selectedSamples).some((key) => {
          const selectedSample = this.selectedSamples[key];
          return this.selectedSampleAnalyses[selectedSample.id]
            && this.selectedSampleAnalyses[selectedSample.id].has(row.id);
        }),
      }));
      this.updateTableRows({ tableKey: 'sampleAnalyses', rows: updatedRows });
    },
    changeAnalysisSelectionStatus(currentRow, value) {
      const { rows } = this.tableParams('sampleAnalyses');
      const updatedRows = rows.map(row => ({
        ...row,
        selected: row.id === currentRow.id ? value : row.selected,
      }));
      this.updateTableRows({ tableKey: 'sampleAnalyses', rows: updatedRows });
    },
    loadOnlySelectedAnalysis() {
      const { rows } = this.tableParams('sampleAnalyses');
      const updatedRows = rows.filter(row => row.selected);
      this.updateTableRows({ tableKey: 'sampleAnalyses', rows: updatedRows });
    },
    loadSampleSelection() {
      this.selectedSamples = storage.get('selectedSamples') || {};
      this.selectedSamplesList = Object.values(this.selectedSamples);
    },
    isIndeterminate(row) {
      if (!row.selected || Object.keys(this.selectedSamples).length < 2) return false;
      const counter = Object.keys(this.selectedSamples)
        .reduce((sum, key) => {
          const sample = this.selectedSamples[key];
          return this.selectedSampleAnalyses[sample.id]
            && this.selectedSampleAnalyses[sample.id].has(row.id) ? sum + 1 : sum;
        }, 0);
      if (counter === 0 || counter === Object.keys(this.selectedSamples).length) return false;
      return Object.keys(this.selectedSamples).length > counter;
    },
    handleAnalysisSelection(row, value) {
      this.uncompletedSamples = Object.keys(this.selectedSamples)
        .reduce((uncompleted, key) => (!this.selectedSamples[key].lot
          ? [...uncompleted, this.selectedSamples[key]] : uncompleted), []);
      const accreditedAnalyses = row.children
        ? row.children.filter(child => child.analysis.accreditedTest)
        : null;
      if (row.hasExpiredOffer && value) {
        // if admin
        if (this.userPermissions && this.userPermissions.includes('admin')) {
          this.changeAnalysisSelectionStatus(row, false);
          this.saveAnalysisSelectionStatus(row, false);
          this.$notify.warning(this.$t('orders.warning.expired'));
        } else {
          // if customer
          this.$confirm(this.$t('orders.warning.message'), this.$t('global.warning'), {
            confirmButtonText: this.$t('orders.warning.confirm'),
            cancelButtonText: this.$t('orders.warning.reject'),
            type: 'warning',
          })
            .then(async () => {
              this.changeAnalysisSelectionStatus(row, false);
              this.backWithoutSaving('requestOffer', { docNumber: row.docNumber });
            })
            .catch(async () => {
              this.changeAnalysisSelectionStatus(row, false);
              this.saveAnalysisSelectionStatus(row, false);
            });
        }
      } else if (
        (row.accreditedTest && value && this.uncompletedSamples.length)
        || (row.classType !== 9999 && value && !row.accreditedTest
          && this.uncompletedSamples.length && accreditedAnalyses.length)
      ) {
        this.accreditedTestModalVisible = true;
        this.currentAccreditedAnalysis = row;
      } else if (row.classType > 0 && row.classType < 9999 && value) {
        this.dynamicAnalysesModalVisible = true;
        this.currentDynamicClass = row;
      } else if (row.classType > 0 && row.classType < 9999 && !value) {
        row.children.forEach((child) => {
          // This implementation needs to be reworked!
          // eslint-disable-next-line
          child.isSelected = false;
        });
        this.saveAnalysisSelectionStatus(row, value);
      } else this.saveAnalysisSelectionStatus(row, value);
    },
    async handleAccreditedTestModalClose({ success, updatedSamples, dirty }) {
      if (!success) {
        this.currentAccreditedAnalysis.selected = false;
        if (dirty && updatedSamples) {
          await this.getOrderSamples();
          Object.keys(updatedSamples).forEach((key) => {
            this.selectedSamples[key].lot = updatedSamples[key].lot;
          });
          this.selectedSamplesList = Object.values(this.selectedSamples);
        }
      } else {
        await this.getOrderSamples();
        Object.keys(updatedSamples).forEach((key) => {
          this.selectedSamples[key].lot = updatedSamples[key].lot;
        });
        this.selectedSamplesList = Object.values(this.selectedSamples);
        if (this.currentAccreditedAnalysis.classType > 0
          && this.currentAccreditedAnalysis.classType < 9999) {
          this.dynamicAnalysesModalVisible = true;
          this.currentDynamicClass = this.currentAccreditedAnalysis;
        } else {
          this.saveAnalysisSelectionStatus(this.currentAccreditedAnalysis, true);
          this.currentAccreditedAnalysis.selected = true;
        }
      }
      this.accreditedTestModalVisible = false;
      this.currentAccreditedAnalysis = null;
    },
    dynamicAnalysesModalClose({ success, analysis }) {
      if (!success) {
        this.currentDynamicClass.selected = false;
      } else {
        this.currentDynamicClass = analysis;
        this.saveAnalysisSelectionStatus(this.currentDynamicClass, true);
        this.currentDynamicClass.selected = true;
      }
      this.dynamicAnalysesModalVisible = false;
      this.currentDynamicClass = null;
    },
    saveAnalysisSelectionStatus(row, value) {
      Object.keys(this.selectedSamples).forEach((key) => {
        const sample = this.selectedSamples[key];
        if (value) {
          if (!this.selectedSampleAnalyses[sample.id]) {
            this.selectedSampleAnalyses[sample.id] = new Map();
          }
          const selectedSampleAnalysesRow = row;
          if (row.classType > 0 && row.classType < 9999 && row.children && row.children.length) {
            const selectedChildren = row.children.filter(child => child.isSelected);
            selectedSampleAnalysesRow.children = selectedChildren;
            selectedSampleAnalysesRow.childrenId = selectedChildren.map(child => child.id);
          }
          this.selectedSampleAnalyses[sample.id].set(row.id, selectedSampleAnalysesRow);
        } else if (!value && this.selectedSampleAnalyses[sample.id]) {
          this.selectedSampleAnalyses[sample.id].delete(row.id);
        }
      });
      storage.set('selectedSampleAnalyses', this.analysesToJson(this.selectedSampleAnalyses));
    },
    removeSampleFromSelectedSampleAnalyses(sampleId) {
      const {
        [sampleId]: removedSample,
        ...restOfselectedSampleAnalyses
      } = this.selectedSampleAnalyses;
      this.selectedSampleAnalyses = restOfselectedSampleAnalyses;
      storage.set('selectedSampleAnalyses', this.analysesToJson(this.selectedSampleAnalyses));
      this.loadAnalysisSelection();
    },
    removeSampleFromSelectedSamples(sampleId) {
      const { [sampleId]: removedSample, ...restOfSelectedSamples } = this.selectedSamples;
      this.selectedSamples = restOfSelectedSamples;
      this.selectedSamplesList = Object.values(this.selectedSamples);
      storage.set('selectedSamples', this.selectedSamples);
    },
    addSampleToSelectedSamples(sampleId, sample) {
      this.selectedSamples[sampleId] = sample;
      this.selectedSamplesList = Object.values(this.selectedSamples);
      storage.set('selectedSamples', this.selectedSamples);
    },
    // refreshSelectedSamples() {
    //   Object.keys(this.selectedSamples).forEach((key) => {
    //     if (this.selectedSamples[key]
    //   });
    // },
    async precompileDraft(forceRefresh = false) {
      if (typeof storage.get('selectedSampleAnalyses') === 'undefined' || forceRefresh) {
        await this.getOrderSamplesAnalyses();
      }
      if (typeof storage.get('selectedSampleAnalyses') !== 'undefined' && !forceRefresh) {
        this.selectedSampleAnalyses = this.jsonToAnalyses(storage.get('selectedSampleAnalyses'));
        // this.handleAnalysisSelection();
        // this.changeSampleSelection(this.selectedSamples);
      }
      if (this.isOrderReadOnly && this.$route.query.productId) {
        const { productId } = this.$route.query;
        const sampleRow = this.orderSamples.find(o => o.id === parseInt(productId, 10));
        this.changeSampleSelection({ row: sampleRow, value: true });
      }
    },
    // getCheckboxValue(row) {
    //   return this.selectedSamples
    //     .some(selectedSample => this.selectedSampleAnalyses[selectedSample.id]
    //       && this.selectedSampleAnalyses[selectedSample.id].has(row.id));
    // },
    analysesToJson(analyses) {
      const list = {};
      Object.keys(analyses).forEach((key) => { list[key] = this.mapToObj(analyses[key]); });
      return list;
    },
    jsonToAnalyses(json) {
      const list = {};
      Object.keys(json).forEach((key) => { list[key] = this.objToMap(json[key]); });
      return list;
    },
    objToMap(obj) {
      const map = new Map();
      Object.keys(obj).forEach(key => map.set(parseInt(key, 10), obj[key]));
      return map;
    },
    mapToObj(map) {
      const obj = {};
      map.forEach((val, key) => { obj[key] = val; });
      return obj;
    },
    async tableRows(withPagination = true) {
      try {
        this.analysisloading = true;
        const tableFilters = withPagination ? this.tableFilters('sampleAnalyses') : {};
        const queryFilters = this.selectOnlyThisSampleEnabled
          ? {} : this.filtersStatus.sampleAnalyses;
        const rows = await offersApi
          .getOfferItemList('it', tableFilters, queryFilters, this.order ? this.order.customerId : null);
        const rowsCount = withPagination ? await offersApi.getOfferItemListCount(
          queryFilters,
          this.order ? this.order.customerId : null,
        ) : { data: { count: 1 } };
        this.updateTableRows({ tableKey: 'sampleAnalyses', rows: rows.data });
        this.updateTableCount({ tableKey: 'sampleAnalyses', count: rowsCount.data.count });
        this.loadAnalysisSelection();
        this.analysisloading = false;
      } catch (error) {
        this.analysisloading = false;
        throw error;
      }
    },
    async getOrder() {
      try {
        const order = await ordersApi.show(this.$route.params.orderId);
        this.order = order.data;
        if (order.data.status === 'sent' && this.$route.query.productId) {
          this.isOrderReadOnly = true;
        }
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async getOrderSample(editSample = true) {
      try {
        const orderSample = await ordersApi
          .showSample(this.$route.params.orderId, this.$route.params.sampleId);
        this.initialSampleData = orderSample.data;
        this.isModalEdit = editSample;
        this.openCreateSampleModal = true;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        this.$router.push({
          name: 'orders',
        });
      }
    },
    async getOrderSamples() {
      try {
        const orderSamples = await ordersApi.samplesIndex(this.$route.params.orderId);
        this.orderSamples = orderSamples.data;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async getOrderSamplesAnalyses() {
      try {
        const orderSamplesAnalyses = await samplesApi.getOrderSamplesAnalyses(
          this.$route.params.orderId,
        );
        orderSamplesAnalyses.data.forEach((sample) => {
          const { sampleId } = sample;
          if (!this.selectedSampleAnalyses[sampleId]) {
            this.selectedSampleAnalyses[sampleId] = new Map();
          }
          sample.offerItems.forEach((item) => {
            this.selectedSampleAnalyses[sampleId].set(item.id, item);
          });
        });
        storage.set('selectedSampleAnalyses', this.analysesToJson(this.selectedSampleAnalyses));
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    handleSampleDialogClose(status) {
      this.openCreateSampleModal = false;
      if (status.success) {
        this.getOrderSamples();
        this.tableRows();
        this.loadAnalysisSelection();
        // commented because the preselection can't work with duplication

        // if (this.currentRoute.name === 'cloneOrderSample' && 'sampleCreated' in status) {
        //   // clone
        //   if (this.currentRoute.params.sampleId in this.selectedSampleAnalyses) {
        //     this.selectedSampleAnalyses[status.sampleCreated.id] = new Map(
        //       this.selectedSampleAnalyses[this.currentRoute.params.sampleId],
        //     );
        //     storage.set('selectedSampleAnalyses',
        //     this.analysesToJson(this.selectedSampleAnalyses));
        //   }
        //   if (this.currentRoute.params.sampleId in this.selectedSamples) {
        //     this.selectedSamples[status.sampleCreated.id] = status.sampleCreated;
        //     this.selectedSamplesList = Object.values(this.selectedSamples);
        //     storage.set('selectedSamples', this.selectedSamples);
        //   }
        // }

        if (status.updatedSample && this.selectedSamples[status.updatedSample.id]) {
          // removing all sample-analyses when the lot field is changed to blank
          if (this.selectedSamples[status.updatedSample.id].lot && !status.updatedSample.lot
            && status.updatedSample.id in this.selectedSampleAnalyses) {
            this.removeSampleFromSelectedSampleAnalyses(status.updatedSample.id);
          }
          this.addSampleToSelectedSamples(status.updatedSample.id, status.updatedSample);
        }
      }

      if (['editOrderSample', 'cloneOrderSample'].includes(this.currentRoute.name)) {
        this.$router.push({
          name: 'editOrder',
          params: { orderId: this.order.id },
        });
      }
    },
    // cloneSample(row) {
    //   this.$router.push({
    //     name: 'cloneOrderSample',
    //     params: { orderId: this.order.id, sampleId: row.id },
    //   });
    // },
    cloneSample(row) {
      this.$confirm(this.$t('samples.clone.confirm'), this.$t('global.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            try {
              instance.confirmButtonLoading = true;
              await this.saveOrderSampleAnalyses();
              await ordersApi.cloneSample(this.order.id, row.id);
              this.$notify({ showClose: true, type: 'success', message: this.$t('samples.clone.success') });
              await this.getOrderSamples();
              await this.tableRows();
              await this.precompileDraft(true);
              this.loadSampleSelection();
              this.loadAnalysisSelection();
              instance.confirmButtonLoading = false;
              done();
            } catch (error) {
              instance.confirmButtonText = 'OK';
              instance.confirmButtonLoading = false;
              done();
              this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
            }
          } else {
            done();
          }
        },
      });
    },
    editSample(row) {
      if (row.isEditPending) {
        this.$alert(
          this.$t('samples.update.waitApproval'),
          this.$t('global.warning'), {
            confirmButtonText: 'OK',
            type: 'warning',
          },
        );
      } else {
        this.$router.push({
          name: 'editOrderSample',
          params: { orderId: this.order.id, sampleId: row.id },
        });
      }
    },
    deleteSample(row) {
      try {
        this.$confirm(this.$t('samples.delete.confirm'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                instance.confirmButtonLoading = true;
                await ordersApi.deleteSample(this.order.id, row.id);
                this.$notify({ showClose: true, type: 'success', message: this.$t('samples.delete.success') });
                await this.getOrderSamples();
                if (row.id in this.selectedSampleAnalyses) {
                  this.removeSampleFromSelectedSampleAnalyses(row.id);
                }
                if (row.id in this.selectedSamples) {
                  this.removeSampleFromSelectedSamples(row.id);
                }
                await this.tableRows();
                this.loadAnalysisSelection();
                instance.confirmButtonLoading = false;
                done();
              } catch (error) {
                instance.confirmButtonLoading = false;
                done();
                this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
              }
            } else {
              done();
              this.$notify({
                type: 'info',
                message: this.$t('samples.delete.cancelled'),
              });
            }
          },
        });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async selectOnlyThisSampleOn(row) {
      this.selectOnlyThisSampleEnabled = true;
      await this.tableRows(false);
      await this.loadAnalysisSelection();
      this.selectOnlyThisSample(row, true);
    },
    async selectOnlyThisSampleOff(row) {
      this.selectOnlyThisSampleEnabled = false;
      this.selectOnlyThisSample(row, false);
      await this.tableRows();
      this.loadAnalysisSelection();
    },
    async selectOnlyThisSample(row, val) {
      this.selectedSamples = {};
      storage.remove('selectedSamples');
      this.changeSampleSelection({ row, value: val });
      this.orderSamples = this.orderSamples
        .map(sample => ({ ...sample, solo: row.id === sample.id ? val : false }));
      this.loadOnlySelectedAnalysis();
    },
    handleChange(file) {
      // const isCSV = file.raw.type === 'text/csv';
      // if (!isCSV) {
      //   this.$notify.error(this.$t('global.notCsvFile'));
      //   this.$refs.upload.clearFiles();
      // }
      this.file = file || null;
      if (this.file) {
        this.$confirm(this.$t('orders.samples.confirmUpload'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => (
            this.importOrderSamples()
          ))
          .then(() => {
            this.$refs.upload.clearFiles();
          })
          .catch((error) => {
            if (error && error.response) {
              this.loading = false;
              this.$notify({
                showClose: true,
                type: 'error',
                message: error.response.data.error.message,
                duration: 0,
              });
            } else {
              this.file = null;
              this.$notify({
                type: 'info',
                message: this.$t('orders.create.cancelled'),
              });
            }
            this.$refs.upload.clearFiles();
          });
      }
      return file;
    },
    handleRemove() {
      this.file = null;
      this.fileList = [];
    },
    handleExceed() {
      this.$notify.warning(this.$t('global.noMultipleFilesUpload'));
    },
    beforeRemove() {
      return this.$confirm(this.$t('global.confirmUploadCancel'));
    },
    async importOrderSamples() {
      this.loading = true;
      await ordersApi.importOrderSamples(this.$route.params.orderId, this.file.raw);
      this.loading = false;
      this.getOrderSamples();
      this.resetSelected();
      this.$notify.success({ message: this.$t('orders.samples.importSuccess'), type: 'success' });
    },
    resetSelected() {
      this.selectedSampleAnalyses = {};
      storage.remove('selectedSamples');
      storage.remove('selectedSampleAnalyses');
    },
    async backWithoutSaving(routeName, query = {}) {
      this.$confirm(this.$t('orders.create.confirmCancel'))
        .then(async () => {
          this.$router.push({ name: routeName, query }); // May change later
        })
        .catch((error) => {
          this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        });
    },
    async goToSummary() {
      if (this.order.status === 'draft') {
        await ordersApi.patchOrder(
          this.order.id,
          { customerOrderNumber: this.order.customerOrderNumber },
        );
      }
      this.$router.push({ name: 'orderSummary', params: { orderId: this.order.id } });
    },
    saveOrderSampleAnalyses() {
      this.postAnalysesData = [];
      Object.keys(this.selectedSampleAnalyses).forEach(async (key) => {
        this.postAnalysesData.push(
          this.buildSamplePostData(
            this.orderSamples.find(sample => sample.id.toString() === key),
          ),
        );
      });
      return samplesApi.orderSamplesAnalyses(this.order.id, this.postAnalysesData);
    },
    buildSamplePostData(sample) {
      const analyses = this.selectedSampleAnalyses[sample.id];
      const formattedAnalyses = [];
      analyses.forEach((analysis) => {
        const formattedAnalysis = { offerItemId: analysis.id };
        const formattedDynamicAnalysis = !analysis.childrenId.length ? [] : analysis.children
          .filter(val => val.isSelected)
          .map(val => val.analysisId);

        if (formattedDynamicAnalysis.length) {
          formattedAnalysis.dynamicAnalyses = formattedDynamicAnalysis;
        }
        formattedAnalyses.push(formattedAnalysis);
        // analysisId: val.analysisId,
        // offerId: val.offerId,
        // dynamicAnalyses: [],
      });
      return {
        sampleId: sample.id,
        analyses: formattedAnalyses,
      };
    },
    canUse(permissions, checkAll = false) {
      return acl.userCanUse(permissions, checkAll);
    },
    async donwloadSamplesTemplate() {
      try {
        const file = await downloadsApi.getSamplesTemplate();
        if (file) {
          return fileSaver.saveAs(file.data, 'Sample_import_template.xlsx');
        }
        return false;
      } catch (error) {
        return error;
      }
    },
    applyDefaultFilters() {
      this.$store.dispatch('applyFilters', {
        filterKey: 'sampleAnalyses',
        filterData: {
          hasExpiredOffer: [{ key: false, label: 'No' }],
        },
      });
    },
  },
};
</script>
<style lang="scss">
  .sample {
    &__action-row {
      & + & {
        margin-top: 20px;
      }
    }
  }
  .samples-header {
    padding-bottom: 20px;
    min-height: $filter-form-min-height;
  }
  .samples-wrapper {
    padding: 0;
    // 100vh - header height - main element padding - top wrapper padding
    height: calc(100vh - #{$header-height} - (#{$--main-padding} * 2));
    overflow-y: auto;

    .samples-import-label {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
  .analyses-wrapper {
    padding: 0;
    border-left: 1px solid $--border-color-base;
    height: calc(100vh - #{$header-height} - (#{$--main-padding} * 2));
    overflow-y: auto;

    .el-table {
      .caret-wrapper {
        height: 23px;

        .sort-caret.ascending {
          top: 0;
        }
        .sort-caret.descending {
          bottom: 0;
        }
      }

      th {
        padding: 6px 0;
      }
      td {
        padding: 2px 0;
      }
    }
  }

  .el-table tr.expired-row {
    background: #e6a23c;
    color: white;
  }

  .el-table tr.expired-row.hover-row > td.el-table__cell {
    color: #62798D;
  }

  .order-upload-help-content {
    p {
      padding-bottom: 1rem;
    }
    strong {
      font-weight: 600;
    }
    ul {
      padding-bottom: 1rem;
      padding-left: 1rem;
      list-style: initial;

      li {
        padding-bottom: 0.3rem;
        padding-top: 0.5rem;
      }
    }
  }
</style>
